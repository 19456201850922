import React, { useContext, useEffect, useState } from "react";
import Content from "../../../layout/content/Content";
import { Card, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from "reactstrap";
import { Alert } from "reactstrap";
import { BlockBetween, BlockDes, BlockContent, BlockHead, BlockTitle, Icon, Col } from "../../../components/Component";
import { Button } from "reactstrap";
import Dropzone from "react-dropzone";
import CopyToClipboard from "react-copy-to-clipboard";
import Head from "../../../layout/head/Head";
import { AuthContext } from "../../../context/AuthContext";
import axiosInstance from "../../../utils/AxiosInstance";
import usePreviewImg from "../../hooks/usePreviewImg";
import useShowToast from "../../hooks/useShowToast";
import qrImg from "../../../images/qr_img.jpg";

const AddFund = () => {
  console.log(process.env.REACT_APP_PUBLIC_URL);
  console.log(process.env.REACT_APP_CLOUDINARY_CLOUD_NAME);
  console.log(process.env.REACT_APP_CLOUDINARY_PRESET_NAME);
  const { userInfo } = useContext(AuthContext);
  const [modal, setModal] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState();

  const [modalForm, setModalForm] = useState(false);
  const [previewQRModal, setPreviewQRModal] = useState(false);

  const toggleForm = () => setModalForm(!modalForm);
  const toggleQRModal = () => setPreviewQRModal(!previewQRModal);

  const [defaultFiles, setDefaultFiles] = useState("");
  const [loading, setLoading] = useState(false);

  // copy clipboard
  const [value, setValue] = useState("");
  const [copied, setCopied] = useState(false);

  const [amount, setAmount] = useState("");
  const [orderId, setOrderId] = useState("");
  const [email, setEmail] = useState("");
  const [screenshotUrl, setScreenshotUrl] = useState("");
  const [isAmountValid, setIsAmountValid] = useState(false);
  const { handleImageChange, imgUrl, setImgUrl } = usePreviewImg();
  const [previewUrl, setPreviewUrl] = useState(null); // Local state for image preview
  const showToast = useShowToast();

  // fetch payment method api
  const fetchPaymentMethod = async () => {
    try {
      const response = await axiosInstance.get("/user/payment-method", {
        headers: {
          Authorization: `Bearer ${userInfo.token}`, // Assuming the token is in the adminInfo context
        },
      });
      setPaymentMethod(response.data.data.paymentMethods[0]);
    } catch (error) {
      console.error("Error fetching payment method:", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchPaymentMethod();
  }, [userInfo.token]);

  // enable/disable button proceed to buy btn
  const handleAmountChange = (e) => {
    const enteredAmount = e.target.value;
    setAmount(enteredAmount);
    if (paymentMethod) {
      const { minAmount, maxAmount } = paymentMethod;
      if (enteredAmount >= minAmount && enteredAmount <= maxAmount) {
        setIsAmountValid(true);
      } else {
        setIsAmountValid(false);
      }
    }
  };

  // Handle image change and generate preview URL
  const handleImageChangeAndPreview = async (e) => {
    // Call the custom hook to handle the image
    const ImgUrl = await handleImageChange(e);
    const file = e.target.files[0]; // Get the selected file
    if (file) {
      const preview = URL.createObjectURL(file); // Generate preview URL
      // Set screenshot
      setPreviewUrl(preview); // Set preview URL

      // setScreenshotUrl(imgUrl);
      // console.log(imgUrl, "img");
      setScreenshotUrl(ImgUrl);
     
    } else {
      setPreviewUrl(null); // Reset preview URL if no file is selected
    }
  };
  // submit data
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!screenshotUrl || !amount || !orderId || !email) {
      showToast("Error", "must upload image", "warning");
      return false;
    }
    setLoading(true); // Set loading state to true when upload starts
    // first upload image
    // if image upload successfully upload data
    const data = new FormData();
    data.append("file", screenshotUrl);
    data.append("upload_preset", process.env.REACT_APP_CLOUDINARY_PRESET_NAME);
    data.append("cloud_name", process.env.REACT_APP_CLOUDINARY_CLOUD_NAME);
    try {
      const res = await fetch(
        `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload`,
        {
          method: "POST",
          body: data,
        }
      );

      const uploadedImageUrl = await res.json();
      if (uploadedImageUrl) {
        const uploadData = {
          amount: parseFloat(amount), // Assuming the amount is numeric
          orderId,
          paymentScreenshotUrl: uploadedImageUrl.secure_url,
          method: paymentMethod?._id,
          email,
        };
        const response = await axiosInstance.post("/user/fund/add", uploadData, {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        });
        // console.log("Fund added successfully:", response.data);
        showToast("Done", "Fund request submitted successfully", "success");
        // Clear all form data after successful submission
        setAmount("");
        setOrderId("");
        setEmail("");
        setScreenshotUrl("");
        setPreviewUrl(null);
        setIsAmountValid(false);
        toggleForm(); // Close the modal on success
        setPreviewUrl(null);
      } else {
        console.error("Error adding screenshot:", res.data);
        showToast("Error", "Error uploading screenshot", "danger");
        setPreviewUrl(null);
      }
    } catch (error) {
      console.error("Error adding fund:", error);
      showToast("Error", "Error adding fund", "danger"); // Error toast
      setPreviewUrl(null);
    } finally {
      setLoading(false); // Set loading state to false when upload finishes
    }
  };

  return (
    <>
      <Head title="Deposit"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween className="g-3">
            <BlockContent>
              <BlockTitle>Deposit</BlockTitle>
              <BlockDes className="text-soft">
                <p>Deposit funds easily and securely</p>
              </BlockDes>
            </BlockContent>
          </BlockBetween>
        </BlockHead>

        <div className="deposit-card">
          <Card className="deposit-card-div">
            <div className="input-div">
              <label>Amount to deposit</label>
              <input
                className="form-control form-control-xl"
                placeholder="Enter amount"
                onChange={handleAmountChange}
              />
              <span>USD</span>
            </div>
            <p className="limit">
              Transaction Limit : {paymentMethod?.minAmount}$ - {paymentMethod?.maxAmount}$
            </p>
            <Button color="primary" disabled={!isAmountValid} onClick={toggleForm} className="buy-btn">
              <span>Continue to deposit</span>
            </Button>
          </Card>
          <div className="deposit-card-div">
            <table className="depo-table">
              <tr>
                <td>Amount</td>
                <td> - </td>
                <td>{amount && amount !== "" ? amount : 0}$</td>
              </tr>
              <tr>
                <td>Charge</td>
                <td> - </td>
                <td>0$</td>
              </tr>
              <tr>
                <td>Payable</td>
                <td> - </td>
                <td>{amount && amount !== "" ? amount : 0}$</td>
              </tr>
              <tr>
                <td>Network</td>
                <td> - </td>
                <td>BNB Smart Chain (BEP20)</td>
              </tr>
            </table>
          </div>
        </div>

        <Modal isOpen={modalForm} toggle={toggleForm}>
          <ModalHeader
            toggle={toggleForm}
            close={
              <button className="close" onClick={toggleForm}>
                <Icon name="cross" />
              </button>
            }
          >
            Pay With Wallet
          </ModalHeader>
          <ModalBody>
            <form onSubmit={handleSubmit}>
              <Alert color="warning">
                <ul>
                  <li>
                    You have requested <span>{amount && amount !== "" ? amount : 0} USDT</span>, Please pay{" "}
                    <span>{amount && amount !== "" ? amount : 0} USDT</span> for successful payment.
                  </li>
                  <li>Send USDT to company wallet by copying the link below.</li>
                  <li>Deposit through Only BNB Smart chain (BEP20).</li>
                  <li>After sending upload its details below.</li>
                </ul>
              </Alert>

              <div className="form-group reffer-section">
                <div className="reffer-div">
                  <label className="form-label">Wallet Address</label>
                  <div className="reffer">
                    <Input
                      className="input"
                      value={paymentMethod?.extraDetails.walletAddress || ""}
                      onChange={({ target: { value } }) => setValue(value)}
                    />
                    <CopyToClipboard text={paymentMethod?.extraDetails.walletAddress} onCopy={() => setCopied(true)}>
                      <Button color="primary" className="buy-btn">
                        <Icon name="copy" />
                      </Button>
                    </CopyToClipboard>
                  </div>
                </div>

                <Button color="primary" onClick={toggleQRModal}>
                  <Icon name="scan" />
                  <span>Preview QR</span>
                </Button>
              </div>

              <div className="form-group">
                <label className="form-label" htmlFor="full-name">
                  Order ID
                </label>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    className="form-control"
                    id="full-name"
                    value={orderId}
                    onChange={(e) => setOrderId(e.target.value)}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="form-label" htmlFor="email">
                  Email
                </label>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    className="form-control"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
              <Col>
                <div className="form-group">
                  <label className="form-label">Screenshot Upload</label>
                  <div className="form-control-wrap">
                    <div className="form-file">
                      <Input type="file" id="customFile" onChange={handleImageChangeAndPreview} />
                    </div>
                  </div>
                </div>
              </Col>

              {/* Image Preview */}
              {previewUrl && (
                <Col md="12" className="mt-4">
                  <div className="form-group">
                    <label className="form-label">Image Preview</label>
                    <div className="form-control-wrap">
                      <img
                        src={previewUrl} // Use preview URL as the src
                        alt="Profile Preview"
                        style={{ width: "100%", height: "200px", borderRadius: "8px", objectFit: "contain" }} // Styling for preview
                      />
                    </div>
                  </div>
                </Col>
              )}

              <Col className="mb-3">
                <Alert color="warning">
                  <ul>
                    <li>Your Deposit request will be approved within 4-6 hours</li>
                  </ul>
                </Alert>
              </Col>

              {/* Uploading Status */}
              {loading && (
                <Col md="12" className="mt-4 text-center">
                  <Spinner color="primary" /> Uploading...
                </Col>
              )}
              <div className="form-group d-flex justify-content-end">
                <a onClick={toggleForm} href="#dropdownitem" className="link link-light me-4">
                  Cancel
                </a>
                <Button color="primary" type="submit" size="lg">
                  {loading ? "Uploading..." : "upload"}
                </Button>
              </div>
            </form>
          </ModalBody>
        </Modal>
        <Modal isOpen={previewQRModal} toggle={toggleQRModal}>
        <ModalHeader
            toggle={toggleQRModal}
            close={
              <button className="close" onClick={toggleQRModal}>
                <Icon name="cross" />
              </button>
            }
          >
            Preview QR
          </ModalHeader>
          <ModalBody>
            <img
              src={qrImg} 
              alt="QR Preview"
            />
          </ModalBody>
        </Modal>
      </Content>
    </>
  );
};

export default AddFund;
