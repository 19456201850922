import React, { useContext, useEffect, useState } from "react";
import Head from "../layout/head/Head";
import Content from "../layout/content/Content";
import img1 from "../images/icons/account-secure.svg";
import img2 from "../images/icons/card-credit.svg";
import img3 from "../images/icons/card-debit.svg";
import img4 from "../images/icons/invoice.svg";
import img5 from "../images/icons/code-scan.svg";
import img6 from "../images/icons/bills.svg";
import img7 from "../images/icons/plan-s3.svg";
import { Button, Card, Input } from "reactstrap";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockBetween,
  BlockTitle,
  PreviewAltCard,
  Row,
  Col,
  LineChartExample,
  Icon,
  PreviewCard,
  BlockContent,
} from "../components/Component";
import { solidLineChart } from "./ChartData";
import TrafficDougnut from "../components/partials/analytics/traffic-dougnut/TrafficDoughnut";
import CopyToClipboard from "react-copy-to-clipboard";
import { AuthContext } from "../context/AuthContext";
import axiosInstance from "../utils/AxiosInstance";
import GraphWrapper from "./GraphWrapper";


const CryptoHomePage = () => {
  const { userInfo } = useContext(AuthContext);
  const [graphData, setGraphData] = useState([]);
  const [weeklyInterest, setWeeklyInterest] = useState([]);
  const [totalCountData, setTotalCountData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [value, setValue] = useState("");
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        setLoading(true);

        // Fetch graph data
        const graphResponse = await axiosInstance.get("/user/dashboard/user-graph-value", {
          headers: {
            Authorization: `Bearer ${userInfo.token}`, // Assuming userInfo has a token property
          },
        });

        // Fetch graph data
        const commonInterestData = await axiosInstance.get("/user/interest/list", {
          headers: {
            Authorization: `Bearer ${userInfo.token}`, // Assuming userInfo has a token property
          },
        });

        // Fetch total count data
        const totalCountResponse = await axiosInstance.get("/user/dashboard/user-total-amount", {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        });

        // Update state with the data from both APIs
        setGraphData(graphResponse.data.data);
        setWeeklyInterest(commonInterestData.data.data.weeklyInterest);
        setTotalCountData(totalCountResponse.data.data);
        // console.log(totalCountResponse.data.data);
        setError(null);
      } catch (err) {
        console.error("Error fetching dashboard data:", err);
        setError("Failed to fetch data. Please try again.");
      } finally {
        setLoading(false);
      }
    };
    if (userInfo && userInfo.userName) {
      setValue(`${process.env.REACT_APP_PUBLIC_URL}/auth-refer-register?referral=${userInfo.userName}`);
    }
    fetchDashboardData();
  }, [userInfo]);

  // useEffect(() => {
  //   const script = document.createElement('script');
  //   script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-market-overview.js';
  //   script.async = true;
  //   script.innerHTML = JSON.stringify({
  //     colorTheme: 'light',
  //     dateRange: '12M',
  //     showChart: false,
  //     locale: 'en',
  //     largeChartUrl: '',
  //     isTransparent: false,
  //     showSymbolLogo: true,
  //     showFloatingTooltip: false,
  //     width: '400',
  //     height: '550',
  //     plotLineColorGrowing: 'rgba(41, 98, 255, 1)',
  //     plotLineColorFalling: 'rgba(41, 98, 255, 1)',
  //     gridLineColor: 'rgba(240, 243, 250, 0)',
  //     scaleFontColor: 'rgba(19, 23, 34, 1)',
  //     belowLineFillColorGrowing: 'rgba(41, 98, 255, 0.12)',
  //     belowLineFillColorFalling: 'rgba(41, 98, 255, 0.12)',
  //     belowLineFillColorGrowingBottom: 'rgba(41, 98, 255, 0)',
  //     belowLineFillColorFallingBottom: 'rgba(41, 98, 255, 0)',
  //     symbolActiveColor: 'rgba(41, 98, 255, 0.12)',
  //     tabs: [
  //       {
  //         title: 'crypto',
  //         symbols: [
  //           {
  //             "s": "MARKETSCOM:BITCOIN"
  //           },
  //           {
  //             "s": "MARKETSCOM:ETHEREUM"
  //           },
  //           {
  //             "s": "COINBASE:SOLUSD"
  //           },
  //           {
  //             "s": "BITSTAMP:XRPUSD"
  //           },
  //           {
  //             "s": "BINANCE:BNBUSD"
  //           },
  //           {
  //             "s": "KRAKEN:USDTUSD"
  //           },
  //           {
  //             "s": "BITSTAMP:USDCUSD"
  //           },
  //           {
  //             "s": "COINBASE:DOGEUSD"
  //           },
  //           {
  //             "s": "CRYPTO:TONUSD"
  //           }
  //         ],
  //       },
  //     ],
  //   });

  //   document.querySelector('.tradingview-widget-container').appendChild(script);
  // }, []);
  const [sm, updateSm] = useState(false);

  // Helper function to get today's interest data
  const getTodayInterestData = () => {
    const today = new Date().toLocaleString("en-US", { weekday: "long" }); // Get the current day name
    return weeklyInterest.find((item) => item.day === today);
  };

  const todayInterest = getTodayInterestData();

  const formatTime = (date) => {
    let hours = date.getUTCHours(); // Use UTC hours to prevent timezone mismatches
    const minutes = date.getUTCMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // if hour is 0, set it to 12
    const minutesStr = minutes < 10 ? "0" + minutes : minutes;
    return `${hours}:${minutesStr} ${ampm}`;
  };

  return (
    <>
      <Head title="Dashboard Overview" />
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>Overview</BlockTitle>
              <BlockDes className="text-soft">
                <p>Welcome to Filan Trading</p>
              </BlockDes>
              <BlockDes className="text-soft">
                <span className="lead-text"> {userInfo.userName}</span>
                <span>{userInfo.ID}</span>
              </BlockDes>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        {loading ? (
          <p>Loading...</p>
        ) : error ? (
          <p>{error}</p>
        ) : (
          <>
            <Block>
              <Row>
                <Col lg="3" className="mb-3">
                  {/* <Card className="card-bordered pricing text-center">
                <div className="pricing-body">
                  <div className="pricing-media">
                    <img src={img1} alt="" />
                  </div>
                  <div className="pricing-title overview">
                    <h5 className="title">$1000.00</h5>
                    <p className="amount">
                      Main Balance
                    </p>
                  </div>
                </div>
              </Card> */}

                  <PreviewCard className="card-bordered reffer-card tot-reffer" bodyClass="card-inner-lg">
                    <BlockHead>
                      <BlockContent>
                        <BlockTitle tag="h5"> Main Balance</BlockTitle>
                      </BlockContent>
                    </BlockHead>
                    <div className="reffer-div w-100">
                      <div className="reffer">
                        <h3>${totalCountData.mainBalance.toFixed(2) || 0}</h3>
                      </div>
                    </div>
                  </PreviewCard>
                </Col>
                <Col lg="3" className="mb-3">
                  {/* <Card className="card-bordered pricing text-center">
                <div className="pricing-body">
                  <div className="pricing-media">
                    <img src={img2} alt="" />
                  </div>
                  <div className="pricing-title overview">
                    <h5 className="title">$1000.00</h5>
                    <p className="amount">
                      Interest Balance
                    </p>
                  </div>
                </div>
              </Card> */}

                  <PreviewCard className="card-bordered reffer-card tot-reffer" bodyClass="card-inner-lg">
                    <BlockHead>
                      <BlockContent>
                        {/* here Interest balance means interest + referred balnce which is stored in earned balance */}
                        <BlockTitle tag="h5">Interest Balance</BlockTitle>
                      </BlockContent>
                    </BlockHead>
                    <div className="reffer-div w-100">
                      <div className="reffer">
                        <h3>${totalCountData.earnedBalance.toFixed(2) || 0}</h3>
                      </div>
                    </div>
                  </PreviewCard>
                </Col>
                <Col lg="3" className="mb-3">
                  {/* <Card className="card-bordered pricing text-center">
                <div className="pricing-body">
                  <div className="pricing-media">
                    <img src={img3} alt="" />
                  </div>
                  <div className="pricing-title overview">
                    <h5 className="title">$1000.00</h5>
                    <p className="amount">
                      Total Deposit
                    </p>
                  </div>
                </div>
              </Card> */}

                  <PreviewCard className="card-bordered reffer-card tot-reffer" bodyClass="card-inner-lg">
                    <BlockHead>
                      <BlockContent>
                        <BlockTitle tag="h5">Total Deposit</BlockTitle>
                      </BlockContent>
                    </BlockHead>
                    <div className="reffer-div w-100">
                      <div className="reffer">
                        <h3>${totalCountData.totalDepositBalance.toFixed(2) || 0}</h3>
                      </div>
                    </div>
                  </PreviewCard>
                </Col>
                <Col lg="3" className="mb-3">
                  {/* <Card className="card-bordered pricing text-center">
                <div className="pricing-body">
                  <div className="pricing-media">
                    <img src={img4} alt="" />
                  </div>
                  <div className="pricing-title overview">
                    <h5 className="title">$1000.00</h5>
                    <p className="amount">
                      Total Earn
                    </p>
                  </div>
                </div>
              </Card> */}

                  <PreviewCard className="card-bordered reffer-card tot-reffer" bodyClass="card-inner-lg">
                    <BlockHead>
                      <BlockContent>
                        <BlockTitle tag="h5">Total Earn</BlockTitle>
                      </BlockContent>
                    </BlockHead>
                    <div className="reffer-div w-100">
                      <div className="reffer">
                        <h3>${totalCountData.totalEarnedBalance.toFixed(2) || 0}</h3>
                      </div>
                    </div>
                  </PreviewCard>
                </Col>
              </Row>
            </Block>
            <Block>
              <Row>
                <Col lg="3" className="mb-3">
                  {/* <Card className="card-bordered pricing text-center">
                <div className="pricing-body">
                  <div className="pricing-media">
                    <img src={img2} alt="" />
                  </div>
                  <div className="pricing-title overview">
                    <h5 className="title">$1000.00</h5>
                    <p className="amount">
                      Total Invest
                    </p>
                  </div>
                </div>
              </Card> */}

                  <PreviewCard className="card-bordered reffer-card tot-reffer" bodyClass="card-inner-lg">
                    <BlockHead>
                      <BlockContent>
                        <BlockTitle tag="h5">Total Invest</BlockTitle>
                      </BlockContent>
                    </BlockHead>
                    <div className="reffer-div w-100">
                      <div className="reffer">
                        <h3>${totalCountData.totalAmountInvested.toFixed(2) || 0}</h3>
                      </div>
                    </div>
                  </PreviewCard>
                </Col>
                <Col lg="3" className="mb-3">
                  {/* <Card className="card-bordered pricing text-center">
                <div className="pricing-body">
                  <div className="pricing-media">
                    <img src={img5} alt="" />
                  </div>
                  <div className="pricing-title overview">
                    <h5 className="title">$1000.00</h5>
                    <p className="amount">
                      Total payout
                    </p>
                  </div>
                </div>
              </Card> */}

                  <PreviewCard className="card-bordered reffer-card tot-reffer" bodyClass="card-inner-lg">
                    <BlockHead>
                      <BlockContent>
                        <BlockTitle tag="h5">Total payout</BlockTitle>
                      </BlockContent>
                    </BlockHead>
                    <div className="reffer-div w-100">
                      <div className="reffer">
                        <h3>${totalCountData.totalWithdrawn.toFixed(2) || 0}</h3>
                      </div>
                    </div>
                  </PreviewCard>
                </Col>
                {/* <Col lg="3" className="mb-3">
              <Card className="card-bordered pricing text-center">
                <div className="pricing-body">
                  <div className="pricing-media">
                    <img src={img6} alt="" />
                  </div>
                  <div className="pricing-title overview">
                    <h5 className="title">$1000.00</h5>
                    <p className="amount">
                      Total Ticket
                    </p>
                  </div>
                </div>
              </Card>
            </Col> */}
                <Col lg="3" className="mb-3">
                  {/* <Card className="card-bordered pricing text-center">
                <div className="pricing-body">
                  <div className="pricing-media">
                    <img src={img7} alt="" />
                  </div>
                  <div className="pricing-title overview">
                    <h5 className="title">$1000.00</h5>
                    <p className="amount">
                      Total Refferal Bonus
                    </p>
                  </div>
                </div>
              </Card> */}

                  <PreviewCard className="card-bordered reffer-card tot-reffer" bodyClass="card-inner-lg">
                    <BlockHead>
                      <BlockContent>
                        <BlockTitle tag="h5">Total Refferal Bonus</BlockTitle>
                      </BlockContent>
                    </BlockHead>
                    <div className="reffer-div w-100">
                      <div className="reffer">
                        <h3>${totalCountData.referralBalance.toFixed(2) || 0}</h3>
                      </div>
                    </div>
                  </PreviewCard>
                </Col>
              </Row>
            </Block>
            <Block>
              <Row className="g-gs">
                <Col lg="8">
                  <PreviewAltCard className="h-100">
                    <GraphWrapper graphData={graphData.graphData} weeklyInterest={weeklyInterest} />
                    {/* <LineChartExample legend={true} data={solidLineChart} /> */}
                  </PreviewAltCard>
                </Col>
                <Col lg="4">
                  <PreviewAltCard>
                    <div className="today-interest-box">
                      <h4 className="mb-4">Today's Interest</h4>
                      {todayInterest ? (
                        <div>
                          <h6 className="text-white">Day : &nbsp;&nbsp;&nbsp; {todayInterest.day}</h6>
                          <h6 className="text-white">Interest :&nbsp;&nbsp;&nbsp; {todayInterest.interest}%</h6>

                          {/* Manual time formatting */}
                          <h6 className="text-white">Credited at :&nbsp;&nbsp;&nbsp; {formatTime(new Date(todayInterest.time))}</h6>
                        </div>
                      ) : (
                        <p>No data for today.</p>
                      )}
                    </div>
                  </PreviewAltCard>
                  <PreviewCard className="card-bordered reffer-card" bodyClass="card-inner-lg">
                    <BlockHead>
                      <BlockContent>
                        <BlockTitle tag="h5">Referral link</BlockTitle>
                        <BlockDes>
                          <p>Invite a Friend and Share the Benefits!</p>
                        </BlockDes>
                      </BlockContent>
                    </BlockHead>
                    <div className="reffer-div w-100">
                      <div className="reffer">
                        <Input
                          className="input"
                          value={value}
                          onChange={({ target: { value } }) => setValue(value)}
                          disabled
                        />
                        <CopyToClipboard text={value} onCopy={() => setCopied(true)}>
                          <Button color="primary" className="buy-btn">
                            <Icon name="copy" />
                          </Button>
                        </CopyToClipboard>
                      </div>
                      {copied ? <p>Link copied!</p> : null} {/* Show message if link copied */}
                    </div>
                  </PreviewCard>
                </Col>
                {/* <Col lg="4">
                  <Row className="g-gs">
                    <Col md="6" lg="12">
                      <PreviewAltCard className="card-full">
                        <TrafficDougnut />
                      </PreviewAltCard>
                    </Col>
                  </Row>
                </Col> */}
              </Row>
            </Block>
            <Block>
              <Row>
                {/* <Col lg='6'>
              <div className="tradingview-widget-container">
                <div className="tradingview-widget-container__widget"></div>
              </div>
            </Col> */}
                {/* <Col lg="6">
                  <PreviewCard className="card-bordered reffer-card" bodyClass="card-inner-lg">
                    <BlockHead>
                      <BlockContent>
                        <BlockTitle tag="h5">Referral link</BlockTitle>
                        <BlockDes>
                          <p>Invite a Friend and Share the Benefits!</p>
                        </BlockDes>
                      </BlockContent>
                    </BlockHead>
                    <div className="reffer-div w-100">
                      <div className="reffer">
                        <Input
                          className="input"
                          value={value}
                          onChange={({ target: { value } }) => setValue(value)}
                          disabled
                        />
                        <CopyToClipboard text={value} onCopy={() => setCopied(true)}>
                          <Button color="primary" className="buy-btn">
                            <Icon name="copy" />
                          </Button>
                        </CopyToClipboard>
                      </div>
                      {copied ? <p>Link copied!</p> : null}
                    </div>
                  </PreviewCard>
                </Col> */}
              </Row>
            </Block>
          </>
        )}
      </Content>
    </>
  );
};

export default CryptoHomePage;
