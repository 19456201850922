import React, { useContext, useEffect, useState } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
  Input,
} from "reactstrap";
import {
  Button,
  Block,
  BlockDes,
  BlockHead,
  BlockTitle,
  Icon,
  PreviewCard,
  BlockContent,
} from "../../../components/Component";
import CopyToClipboard from "react-copy-to-clipboard";
import { Link } from "react-router-dom";
import Logo from "../../../../src/images/logo.png";
import LogoDark from "../../../../src/images/logo-dark.png";
import { AuthContext } from "../../../context/AuthContext";

const Refferal = () => {
  const { userInfo } = useContext(AuthContext); // Get userInfo from AuthContext

  // copy clipboard
  const [value, setValue] = useState("");
  const [copied, setCopied] = useState(false);

  // Set the referral link when the component mounts
  useEffect(() => {
    if (userInfo && userInfo.userName) {
      setValue(`${process.env.REACT_APP_PUBLIC_URL}/auth-refer-register?referral=${userInfo.userName}`);
    }
  }, [userInfo]);

  return (
    <>
      <Head title="Reffer Friend"></Head>

      <Content>
        <Block className="nk-block-middle nk-auth-body wide-xs">
          <div className="brand-logo pb-4 text-center">
            <Link to={process.env.PUBLIC_URL + "/"}>
              <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
              <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" />
            </Link>
          </div>
          <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
            <BlockHead>
              <BlockContent>
                <BlockTitle tag="h5">Referral link</BlockTitle>
                <BlockDes>
                  <p>Invite a Friend and Share the Benefits!</p>
                </BlockDes>
              </BlockContent>
            </BlockHead>

            <div className="reffer-div w-100">
              <div className="reffer">
                <Input
                  className="input"
                  value={value}
                  onChange={({ target: { value } }) => setValue(value)} disabled // Update value on input change
                />
                <CopyToClipboard text={value} onCopy={() => setCopied(true)}>
                  <Button color="primary" className="buy-btn">
                    <Icon name="copy" />
                  </Button>
                </CopyToClipboard>
              </div>
              {copied ? <p>Link copied!</p> : null} {/* Show message if link copied */}
            </div>
          </PreviewCard>
        </Block>
      </Content>
    </>
  );
};

export default Refferal;